var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',_vm._l((_vm.filteredItems),function(product){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:((_vm.showOnlyRunout && _vm.isRunout(product)) || !_vm.showOnlyRunout),expression:"(showOnlyRunout && isRunout(product)) || !showOnlyRunout"}],key:product.id},[_c('td',[_c('router-link',{attrs:{"to":'products/' + product.id}},[_c('i',{staticClass:"fi-foot"},[_vm._v(" "+_vm._s(product.name))]),_vm._v(" "+_vm._s(product.en_name)+" ")])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.columns.find(function (column) { return column.name == 'code'; }).show),expression:"columns.find(column => column.name == 'code').show"}]},[_vm._v(" "+_vm._s(product.code)+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.columns.find(function (column) { return column.name == 'size'; }).show),expression:"columns.find(column => column.name == 'size').show"}]},[_vm._v(" "+_vm._s(product.size)+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.columns.find(function (column) { return column.name == 'stock'; }).show),expression:"columns.find(column => column.name == 'stock').show"}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toNum")(product.stock,1))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.columns.find(function (column) { return column.name == 'purchases'; }) &&
          _vm.columns.find(function (column) { return column.name == 'purchases'; }).show
      ),expression:"\n        columns.find(column => column.name == 'purchases') &&\n          columns.find(column => column.name == 'purchases').show\n      "}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toNum")(product.purchases,1))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.columns.find(function (column) { return column.name == 'sells'; }) &&
          _vm.columns.find(function (column) { return column.name == 'sells'; }).show
      ),expression:"\n        columns.find(column => column.name == 'sells') &&\n          columns.find(column => column.name == 'sells').show\n      "}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toNum")(product.sells,1))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.columns.find(function (column) { return column.name == 'profit'; }) &&
          _vm.columns.find(function (column) { return column.name == 'profit'; }).show
      ),expression:"\n        columns.find(column => column.name == 'profit') &&\n          columns.find(column => column.name == 'profit').show\n      "}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.profit(product)))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.columns.find(function (column) { return column.name == 'runout'; }) &&
          _vm.columns.find(function (column) { return column.name == 'runout'; }).show
      ),expression:"\n        columns.find(column => column.name == 'runout') &&\n          columns.find(column => column.name == 'runout').show\n      "}],staticClass:"text-right",class:{ runout: _vm.isRunout(product) }},[_vm._v(" "+_vm._s(_vm._f("toLocaleDateString")(_vm.runout(product),'hu-HU'))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.columns.find(function (column) { return column.name == 'runout'; }) &&
          _vm.columns.find(function (column) { return column.name == 'runout'; }).show
      ),expression:"\n        columns.find(column => column.name == 'runout') &&\n          columns.find(column => column.name == 'runout').show\n      "}],staticClass:"text-right",class:{ runout: _vm.isRunout(product) }},[_vm._v(" "+_vm._s(-parseInt((product.sells / product.sellDays) * _vm.days))+" "+_vm._s(_vm.products.lastPurchaseDate)+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.columns.find(function (column) { return column.name == 'avarage purchase price'; }) &&
          _vm.columns.find(function (column) { return column.name == 'avarage purchase price'; }).show
      ),expression:"\n        columns.find(column => column.name == 'avarage purchase price') &&\n          columns.find(column => column.name == 'avarage purchase price').show\n      "}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(product.avaragePurchasePrice))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.columns.find(function (column) { return column.name == 'last purchase price'; }) &&
          _vm.columns.find(function (column) { return column.name == 'last purchase price'; }).show
      ),expression:"\n        columns.find(column => column.name == 'last purchase price') &&\n          columns.find(column => column.name == 'last purchase price').show\n      "}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(product.lastPurchasePrice))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.columns.find(function (column) { return column.name == 'avarage purchase price'; }) &&
          _vm.columns.find(function (column) { return column.name == 'avarage purchase price'; }).show
      ),expression:"\n        columns.find(column => column.name == 'avarage purchase price') &&\n          columns.find(column => column.name == 'avarage purchase price').show\n      "}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")((product.stock * product.avaragePurchasePrice)))+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.columns.find(function (column) { return column.name == 'last purchase price'; }) &&
          _vm.columns.find(function (column) { return column.name == 'last purchase price'; }).show
      ),expression:"\n        columns.find(column => column.name == 'last purchase price') &&\n          columns.find(column => column.name == 'last purchase price').show\n      "}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")((product.stock * product.lastPurchasePrice)))+" ")]),_vm._l((_vm.groups),function(column){return _c('td',{directives:[{name:"show",rawName:"v-show",value:(column.show),expression:"column.show"}],key:column.name,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(((1 + column.percentage / 100) * product.lastPurchasePrice)))+" ")])})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }